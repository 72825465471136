@import "variables";
@import "fonts";
@import "icons";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/swiper/swiper";
@import "../node_modules/suggestions-jquery/dist/css/suggestions";

html, body {
  font-family: 'SuisseIntl-Regular';
  letter-spacing: 0;
  font-weight: 400;
  //min-height: 100% !important;
}

p {
  margin-bottom: 1.5rem;
}

a {
  text-decoration: none !important;
  &:not(.btn):hover {
    opacity: 0.7;
    @include animate(all, .1s)
  }
}

iframe {
  max-width: 100%;
}

.fw-bolder {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.w-md-auto {
  @include media-breakpoint-up(md) {
    width: auto !important;
  }
}

.w-md-75 {
  @include media-breakpoint-up(md) {
    width: 75% !important;
  }
}

.border-bottom {
  &-md {
    @include media-breakpoint-down(md) {
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
  }
}

.card {
  &._no-md-border {
    @include media-breakpoint-down(md) {
      border: none !important;
    }
  }
  &-img-top {
    height: 300px;
    object-fit: contain;
    @include media-breakpoint-up(md) {
      height: 220px;
    }
    @include media-breakpoint-up(lg) {
      height: 240px;
    }
    @include media-breakpoint-up(xl) {
      height: 380px;
    }
    &._bordered-md {
      @include media-breakpoint-down(md) {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
      }
    }
  }
}

.disabled {
  opacity: 0.7 !important;
  user-select: none !important;
  pointer-events: none !important;
}

.w-max-100 {
  max-width: 100%;
}

.h-450 {
  height: 450px;
  object-fit: cover;
  @include media-breakpoint-up(md) {
    height: auto;
  }
}

.h-max-300 {
  max-height: 300px;
}

.list-style-dash {
  list-style-type: none;
  padding-left: 5px;
  text-indent: 5px;
  margin-bottom: 1.5rem;
  & > li:before {
    content: "-";
    left: -5px;
    position: relative;
  }
}

.modal {
  padding: 0 !important;
  @include media-breakpoint-up(md) {
    padding: 0 40px 0 20px !important;
  }
  &__close {
    background: none;
    border: none;
    height: 24px;
    width: 24px;
    color: $theme-dark-grey;
  }
  &-header, &-footer {
    border: none;
  }
  &-dialog {
    margin: 0 auto !important;
    @include media-breakpoint-up(md) {
      margin: var(--bs-modal-margin);
    }
    &._full-width {
      width: 100% !important;
      max-width: 1290px !important;
    }
  }
  &-content {
    @include media-breakpoint-down(md) {
      border-radius: 0 !important;
    }
  }
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #000 #0000;
  animation: l1 1s infinite;
  margin: 0 auto;
}

@keyframes l1 {
  to {
    transform: rotate(.5turn)
  }
}

.wrapper {
  height: 100%;
  overflow: hidden;
}

.container {
  padding: 0 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  max-width: 1440px !important;
  @include media-breakpoint-up(md) {
    padding: 0 40px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 60px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.form-control {
  border-radius: 8px !important;
  padding: 9px 16px !important;
  &::placeholder {
    color: $theme-grey;
  }
  &._minimize {
    background: none !important;
    border: none;
    border-bottom: 1px solid $theme-light-grey;
    border-radius: 0 !important;
    padding: 4px 20px !important;
  }
  &-wrapper {
    position: relative;
    label.error, div.error {
      color: $theme-red;
      font-size: 13px;
      line-height: 15px;
    }
    textarea.error, input.error {
      border-color: $theme-red !important;
    }
    &._hide-error {
      label.error {
        display: none !important;
      }
    }
  }
}

textarea:focus,
select:focus,
input:focus,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.breadcrumb {
  line-height: 1.2;
  &-item {
    color: $theme-dark-grey !important;
    font-weight: 400;
    a {
      color: $theme-dark-grey !important;
      font-weight: 400;
    }
    &:first-of-type {
      a {
        display: flex;
        &:before {
          content: url('data:image/svg+xml,<svg width="10.000000" height="18.000000" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs/><path id="↳ Icon Color" d="M2.41 9L9.7 16.29C10.09 16.68 10.09 17.31 9.7 17.7C9.31 18.09 8.68 18.09 8.29 17.7L0.29 9.7C-0.1 9.31 -0.1 8.68 0.29 8.29L8.29 0.29C8.68 -0.1 9.31 -0.1 9.7 0.29C10.09 0.68 10.09 1.31 9.7 1.7L2.41 9Z" fill="%23534F50" fill-opacity="1.000000" fill-rule="nonzero"/></svg>');
          display: block;
          margin-right: 10px;
          height: 18px;
          width: 10px;
        }
      }
    }
    & + .breadcrumb {
      &-item {
        &:before {
          letter-spacing: 1.5px;
        }
      }
    }
    &.active {
      font-weight: 500 !important;
    }
  }
}

.bg {
  &-green {
    background-color: $theme-green;
  }
  &-grey {
    background-color: $theme-grey;
  }
  &-light-grey {
    background-color: $theme-light-grey;
  }
  &-ultralight-grey {
    background-color: $theme-ultralight-grey;
  }
  &-milky {
    background-color: $theme-milky;
  }
}

.text {
  &-dark-grey {
    color: $theme-dark-grey
  }
  &-grey {
    color: $theme-grey
  }
  &-semiblack {
    color: $theme-semiblack
  }
  &-large {
    font-size: 16px;
    line-height: 22px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 26px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &-small {
    font-size: 13px;
    line-height: 15px;
  }
  &-strike {
    &:after {
      content: "";
      display: block;
      width: calc(100% + 10px);
      border-bottom: 1px solid $theme-dark-grey;
      top: -50%;
      left: -5px;
      position: relative;
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  animation: rotate 1s linear infinite;
  z-index: 55;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite;
  }
  &-bg {
    &:before {
      content: "";
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}

.btn {
  @include animate(all, .1s);
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  &._compact {
    padding: 6px 12px !important;
    font-size: 14px;
  }
  &-grey {
    background-color: $theme-grey;
    color: $theme-white;
    &:hover, &:active {
      opacity: 1;
      background-color: $theme-dark-grey;
      color: $theme-white;
    }
  }
  &-primary {
    border: 1px solid $theme-black;
    &:hover {
      background-color: $theme-green;
      border-color: $theme-green;
      color: $theme-black;
    }
  }
  &-outline {
    &-primary {
      &:hover {
        border-color: $theme-green;
        background-color: rgba(255, 255, 255, 0);
        color: $theme-black;
      }
    }
  }
  &-icon {
    @include animate(all, .1s);
    color: $theme-black;
    border: none;
    background: none;
    height: 24px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 22px;
    text-align: left;
    &._trash {
      background-image: url('../image/trash.svg');
    }
    opacity: 1;
    &:disabled {
      opacity: 0.75 !important;
    }
  }
  &:disabled {
    background-color: $theme-grey;
    border-color: $theme-grey;
  }
}

.checkbox {
  @include animate(all, .1s);
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: $theme-black;
  display: flex;
  flex-direction: column;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 6px;
    border: 1px solid $theme-dark-grey;
    background: $theme-white;
  }
  &:hover input ~ .checkmark {
    border: 1px solid $theme-black;
    background: $theme-light-grey;
  }
  & input:checked ~ .checkmark {
    background: url('../image/checkmark.svg');
    background-size: 19px;
  }
  & input.error ~ .checkmark {
    border: 1px solid $theme-red;
  }
  label.error {
    order: 5;
    margin-top: 12px;
    padding-left: 9px;
  }
}

.radio {
  @include animate(all, .1s);
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: $theme-black;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid $theme-dark-grey;
    background: $theme-white;
  }
  &:hover input ~ .radiomark {
    border: 1px solid $theme-black;
    background: $theme-light-grey;
  }
  input:checked ~ .radiomark {
    background-color: $theme-white;
  }
  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .radiomark:after {
    display: block;
  }
  .radiomark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $theme-black;
  }
}

.logo {
  font-size: 28px;
  line-height: 36px;
  color: $primary;
}

header {
  .nav {
    font-size: 16px;
    line-height: 24px;
    color: $theme-dark-grey;
    &-item {
      position: relative;
      .cart_count {
        position: absolute;
        border: 1px solid $theme-black;
        border-radius: 50%;
        padding: 4px 0;
        text-align: center;
        font-size: 10px;
        line-height: 8px;
        height: 16px;
        width: 16px;
        top: 2px;
        right: -18px;
        @include media-breakpoint-up(md) {
          top: 0px;
          right: 0px;
        }
      }
    }
  }
}

.nav-burger {
  width: 20px;
  height: 16px;
  position: relative;
  margin: auto 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: $theme-black;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(even) {
      left: 50%;
    }
    &:nth-child(odd) {
      left: 0px;
    }
    &:nth-child(1), &:nth-child(2) {
      top: 0px;
    }
    &:nth-child(3), &:nth-child(4) {
      top: 7px;
    }
    &:nth-child(5), &:nth-child(6) {
      top: 14px;
    }
  }
  &._opened span:nth-child(1), &._opened span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &._opened span:nth-child(2), &._opened span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &._opened span:nth-child(1) {
    left: 2px;
    top: 4px;
  }
  &._opened span:nth-child(2) {
    left: calc(50% - 2px);
    top: 4px;
  }
  &._opened span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  &._opened span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  &._opened span:nth-child(5) {
    left: 2px;
    top: 10px;
  }
  &._opened span:nth-child(6) {
    left: calc(50% - 2px);
    top: 10px;
  }
}

.notification {
  background-color: $theme-green;
  position: relative;
  &__close {
    background-color: $theme-green !important;
    position: absolute;
    top: calc(50% - 17px);
    right: 0;
    background: none;
    border: none;
    width: 34px;
    height: 34px;
  }
  &__inner {
    display: flex;
  }
  &__line {
    @include media-breakpoint-down(md) {
      white-space: nowrap;
      animation-name: marqueeLine;
      animation-duration: 30s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

.marquee {
  padding: 42px 0;
  overflow: hidden;
  height: 180px;
  &__inner {
    display: flex;
  }
  &__line {
    flex-shrink: 0;
    margin: 0 0 0 20px;
    //min-width: 100%;
    white-space: nowrap;
    animation-name: marqueeLine;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    font-size: 96px;
    line-height: 1;
    color: black;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 1px $theme-black;
  }
}

@keyframes marqueeLine {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.accordion {
  &-button {
    padding: 10px 0;
    @include media-breakpoint-up(md) {
      padding: 16px 0;
    }
    &._no-bg {
      border: none !important;
      background: none !important;
    }
    &::after {
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      background-image: url('data:image/svg+xml,<svg width="32.000000" height="32.000000" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs/><line id="Line 4" x1="9.000000" y1="16.000000" x2="23.000000" y2="16.000000" stroke="%23231F20" stroke-opacity="1.000000" stroke-width="2.000000"/><line id="Line 4" x1="16.000000" y1="9.000000" x2="16.000000" y2="23.000000" stroke="%23231F20" stroke-opacity="1.000000" stroke-width="2.000000"/><circle id="Ellipse 2" cx="15.714355" cy="15.714355" r="15.000000" stroke="%23231F20" stroke-opacity="1.000000" stroke-width="1.428571"/></svg>');
      @include animate(transform, .1s);
    }
    &:not(.collapsed)::after {
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      background-image: url('data:image/svg+xml,<svg width="32.000000" height="32.000000" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs/><line id="Line 4" x1="9.000000" y1="16.000000" x2="23.000000" y2="16.000000" stroke="%23231F20" stroke-opacity="1.000000" stroke-width="2.000000"/><line id="Line 4" x1="16.000000" y1="9.000000" x2="16.000000" y2="23.000000" stroke="%23231F20" stroke-opacity="1.000000" stroke-width="2.000000"/><circle id="Ellipse 2" cx="15.714355" cy="15.714355" r="15.000000" stroke="%23231F20" stroke-opacity="1.000000" stroke-width="1.428571"/></svg>');
      transform: rotateZ(45deg);
    }
    &._no-bg {
      border: none !important;
      box-shadow: none !important;
      background: none !important;
    }
  }
  &-item {
    &._bordered {
      border-top: 1px solid $theme-black !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
      &:last-of-type {
        @include media-breakpoint-up(md) {
          border-bottom: 1px solid $theme-black !important;
        }
      }
    }
    &._permanent {
      border-bottom: 1px solid $theme-black !important;
    }
    &._no-bg {
      background: none !important;
    }
  }
  &-body {
    padding: 0;
    color: $theme-dark-grey;
    line-height: 24px;
    font-size: 16px;
  }
}

.card {
  position: relative;
  padding: 2px 0;
  &__tag {
    position: absolute;
    display: block;
    background-color: $theme-green;
    border-radius: 4px 0 6px 0;
    padding: 4px 8px;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 15px;
    z-index: 15;
    @include media-breakpoint-down(md) {
      margin-left: 10px;
    }
  }
  &__image {
    height: 200px;
    display: block;
    flex-shrink: 0;
    display: flex !important;
    align-items: center;
    @include media-breakpoint-up(lg) {
      height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-body {
    flex: 0 1 auto;
    height: 100%;
  }
}

.product-img {
  &__main {
    position: relative;
    height: auto;
    border-radius: 16px;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    @include media-breakpoint-up(md) {
      height: 400px;
      border: none !important;
    }
    &-item {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      object-fit: contain;
    }
    img{
      cursor: zoom-in;
    }
    &._single{
      height: 400px;
      @include media-breakpoint-up(md) {
        height: 500px;
      }
    }
    .zoom-box{
      width: 100%;
      height: 100%;
      display: inline-block;
      position: relative;
      overflow: hidden;
      &:hover{
        box-shadow: 0 0 10px rgba(0,0,0,0.75);
      }
    }
  }
  &__thumbnails {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    &-img {
      border-radius: 6px;
      overflow: hidden;
      flex-basis: calc(25% - 10px);
      padding: 1px;
      box-sizing: content-box;
      cursor: pointer;
      &:last-of-type{
        margin-right: 0px !important;
      }
      &._active {
        border: 1px solid $theme-green;
        padding: 0;
      }
      img {
        width: 100%;
        //max-height: 100px;
        aspect-ratio: 2 / 1.5;
        object-fit: contain;
        object-position: center center;
      }
    }
  }
}

.certificate-values {
  display: flex;
  gap: 12px;
  &__item {
    height: 46px;
    min-width: calc(19% - 2px) !important;
    background-color: $theme-green;
    font-weight: 500;
    font-size: 16px;
    padding: 12px 16px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    min-width: 96px !important;
    max-width: 96px !important;
    @include media-breakpoint-up(lg) {
      min-width: 84px !important;
      max-width: 96px !important;
    }
    &:first-of-type {
      @include media-breakpoint-down(lg) {
        margin-left: 30px !important;
      }
    }
    &:last-of-type {
      @include media-breakpoint-down(lg) {
        margin-right: 30px !important;
      }
    }
    &-input {
      background: none;
      max-width: 65%;
      margin: 0 5%;
      border: none;
      border-bottom: 1px solid $theme-black;
    }
    &._active {
      border: 1px solid $theme-black !important;
      padding: 11px 15px;
    }
    &._custom {
      background: none !important;
      border: 1px solid $theme-green;
      padding: 11px 16px;
    }
  }
}

.slider {
  position: relative;
  display: flex;
  &__btn {
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7;
    flex-shrink: 0;
    z-index: 50;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include animate(all, .1s);
    &:hover {
      opacity: 1;
    }
    &._prev {
      left: 0;
      background-image: url('../image/arrow-left.svg');
    }
    &._next {
      right: 0;
      background-image: url('../image/arrow-right.svg');
    }
  }
  .swiper-slide {
    width: auto;
    overflow: hidden;
  }
}

.pic-slider {
  padding: 0 20px;
  height: 400px;
  @include media-breakpoint-up(md) {
    padding: 0 80px;
    height: auto;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 110px;
  }
  img {
    @include media-breakpoint-down(md) {
      object-fit: cover;
      height: 100%;
    }
  }
}

//.main-slider{
//  position: relative;
//  display: flex;
//  .swiper-slide{
//    width: auto !important;
//  }
//}

.certificates {
  //display: flex;
  //gap: 8px;
  & > div:first-of-type {
    margin-left: 30px;
  }
  & > div:last-of-type {
    margin-right: 30px;
  }
  &__item {
    width: fit-content;
    height: 66px;
    border-radius: 6px;
    cursor: pointer;
    padding: 1px;
    img {
      max-height: 100%;
    }
    &._active {
      border: 1px solid $theme-black;
      padding: 0;
    }
  }
}

.certificate {
  margin: 0 auto;
  max-width: 350px;
  @include media-breakpoint-up(lg) {
    margin: 0 20px;
    box-shadow: 9px 15px 50px 0 rgba(0, 0, 0, 0.1);
  }
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  img {
    display: none;
    width: 100%;
  }
}

.cart {
  &-content {
    &__products {
      &-item {
        padding: 28px 0;
        border-bottom: 1px solid $theme-light-grey;
        &:last-of-type {
          border: none;
        }
      }
      &-inner {
        display: flex;
        gap: 24px;
        flex-direction: column;
        padding-bottom: 24px;
        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }
      &-image {
        max-width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 200px;
        }
        border-radius: 14px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      &-params {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 300px;
        }
      }
    }
  }
  &-checkout {
    &-form {
      border: 1px solid $theme-dark-grey;
      border-radius: 12px;
      padding: 24px;
      &._checkout {
        border: 1px solid $theme-light-grey;
      }
    }
  }
}

.checkout {
  &-order {
    &-item {
      display: flex;
      align-items: start;
      gap: 12px;
      margin-bottom: 24px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &__index {
        height: 24px;
        width: 24px;
        background-color: $theme-green;
        border-radius: 6px;
        font-weight: 500;
        flex-shrink: 0;
        text-align: center;
      }
      &__image {
        border-radius: 14px;
        border: 1px solid $theme-light-grey;
        min-width: 120px;
        max-width: 160px;
        overflow: hidden;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      &__params {
        width: 100%;
      }
    }
  }
}

.counter {
  border: 1px solid $theme-black;
  border-radius: 6px;
  display: flex;
  gap: 6px;
  width: 100%;
  justify-content: center;
  @include media-breakpoint-up(md) {
    width: unset;
  }
  &__btn {
    border: none;
    background: none;
    padding: 15px;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 42px;
    &:disabled {
      color: $theme-dark-grey;
      opacity: 0.75 !important;
    }
    &._plus {
      background-image: url('../image/plus.svg');
    }
    &._minus {
      background-image: url('../image/minus.svg');
    }
    &._large {
      padding: 23px;
    }
  }
  &__input {
    border: none;
    text-align: center;
    width: 30px;
    background-color: $theme-white !important;
    :disabled {
      color: $theme-grey !important;
    }
  }
}

.boxberry_container {
  top: calc(50% - 280px) !important;
  max-width: 1300px !important;
}

.cdek-map .cdek-jipbqv.cdek-any3id[data-v-69760bf9], .cdek-map .cdek-fylsw1[data-v-69760bf9] {
  width: 100% !important;
  max-width: 1300px !important;
}

.cdek-map .cdek-t4f5h0[data-v-69760bf9], .boxberry_overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(0.5);
}

.ymaps-2-1-79-default-cluster {
  color: $theme-white;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 62px !important;
}

#shipping-map {
  top: 0;
  height: 100%;
  overflow: visible;
  @include media-breakpoint-up(md) {
    top: 50%;
    transform: translateY(-50%);
    min-height: 320px !important;
    max-height: 600px !important;
    height: 80% !important;
  }
  .modal-dialog {
    border-radius: 24px;
    border: 1px solid $theme-light-grey;
  }
  .modal-content {
    background-color: $theme-white;
    border-radius: 22px;
    overflow: hidden;
    border: none !important;
  }
  .modal__close {
    position: absolute;
    top: calc(50% + 24px);
    right: 24px;
    z-index: 500;
    @include media-breakpoint-up(md) {
      top: 24px;
      right: 24px;
    }
  }
}

.shipping-map {
  display: flex;
  background-color: $theme-white;
  flex-direction: column-reverse;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  &__content {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      height: 60%;
      min-height: 410px;
    }
    @include media-breakpoint-up(md) {
      width: 480px;
    }
    &-wrap {
      height: calc(100% - 70px);
      //display: flex;
      //flex-direction: column;
    }
  }
}

.pvz-list-block {
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  &__item {
    user-select: none;
    padding: 10px 12px;
    cursor: pointer;
    &:not(:last-of-type) {
      border-bottom: 1px solid $theme-light-grey;
    }
    &:hover {
      background-color: rgba(112, 112, 112, 0.1);
    }
  }
}

.pvz-info-block {
  //position: relative;
  background-color: $theme-white;
  height: calc(100% - 20px);
  //overflow: hidden;
  //display: flex;
  //flex-direction: column;
  &__text {
    user-select: none;
    flex-shrink: 1;
    max-height: 60%;
    &-wrap {
      max-height: 60%;
      border-radius: 12px;
      overflow: hidden;
    }
  }
  &__btn {
    height: 24px;
    width: 24px;
    margin-right: 12px;
    background: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../image/arrow-left.svg');
    border: none;
  }
  &__list {
    list-style-type: none;
    &-item {
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      user-select: none;
      &:before {
        position: relative;
        display: block;
        content: "";
        height: 24px;
        width: 24px;
        margin-right: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        flex-shrink: 0;
      }
      &._ballon {
        &:before {
          background-image: url('../image/ballon-icon.svg');
        }
      }
      &._calendar {
        &:before {
          background-image: url('../image/calendar-icon.svg');
        }
      }
      &._time {
        &:before {
          background-image: url('../image/time-icon.svg');
        }
      }
      &._box {
        &:before {
          background-image: url('../image/box-icon.svg');
        }
      }
    }
  }
}

.scrollbar-track-y {
  width: 4px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.about-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 15vh);
  grid-gap: 16px;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 20vh);
    grid-gap: 30px;
  }
  @include media-breakpoint-up(lg) {
    grid-template-rows: repeat(3, 25vh);
    grid-gap: 40px;
  }
  &__item {
    display: inline-grid;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &._b1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 4;
      @include media-breakpoint-up(md) {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 4;
      }
    }
    &._b2 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
      @include media-breakpoint-up(md) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }
    }
    &._b3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 4;
      @include media-breakpoint-up(md) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 4;
      }
    }
    &._b4 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;
      @include media-breakpoint-up(md) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
    &._b5 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 5;
      @include media-breakpoint-up(md) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
      }
    }
  }
}

.about-images-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 260px);
  grid-gap: 16px;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 200px);
    grid-gap: 30px;
  }
  @include media-breakpoint-up(lg) {
    grid-template-rows: repeat(2, 260px);
    grid-gap: 40px;
  }
  &__item {
    display: inline-grid;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &._b1 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
      @include media-breakpoint-up(md) {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
    &._b2 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
      @include media-breakpoint-up(md) {
        grid-column-start: 7;
        grid-column-end: 10;
        grid-row-start: 1;
        grid-row-end: 2;
      }
    }
    &._b3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      @include media-breakpoint-up(md) {
        grid-column-start: 7;
        grid-column-end: 10;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
    &._b4 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      @include media-breakpoint-up(md) {
        grid-column-start: 10;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
}

.about-video {
  height: 240px;
  @include media-breakpoint-up(md) {
    height: 480px;
  }
  @include media-breakpoint-up(lg) {
    height: 660px;
  }
}

.swiper {
  &-button {
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 50;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.9;
    cursor: pointer;
    &-prev {
      left: 8px;
      background-image: url('../image/arrow-slider-left.png');
    }
    &-next {
      right: 8px;
      background-image: url('../image/arrow-slider-right.png');
    }
  }
  &-slide {
    height: auto;
  }
}

.cart-product-text {
  p:last-of-type {
    margin-bottom: 0 !important;
  }
}

.zoom-box{
  position: relative;
  img{
    vertical-align: bottom;
  }
  .zoom-selector{
    position: absolute;
    background-image: url("../image/selector.png");
    background-repeat: repeat;
    display: none;
    cursor: crosshair;
  }
  .viewer-box{
    position: absolute;
    border: 1px solid rgb(239,237,240);
    display: none;
    overflow: hidden;
    & > img{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

textarea.form-control {
  min-height: calc(7.6rem + calc(var(--bs-border-width)* 2));
}