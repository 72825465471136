$theme-bg:          #ffffff !default;
$theme-white:        #ffffff !default;
$theme-green:       #CFD8BE !default;
$theme-black:       #231F20 !default;
$theme-semiblack:   #7F7F7F !default;
$theme-grey:        #BCBDC0 !default;
$theme-ultralight-grey:        #F6F6F6 !default;
$theme-dark-grey:   #534F50 !default;
$theme-light-grey:  #E5E5E5 !default;
$theme-red:         #DB5151 !default;
$theme-milky:       #F4F3EF !default;
$theme-fog:         #F5F5F4 !default;

$input-btn-padding-y:         .55rem !default;
$input-btn-padding-x:         2rem !default;
$input-btn-padding-y-sm:      .530rem !default;
$input-btn-padding-x-sm:      1.4rem !default;
$input-btn-padding-y-lg:      .75rem !default;
$input-btn-padding-x-lg:      2rem !default;

//$input-btn-padding-y:         .65rem !default;
//$input-btn-padding-x:         1rem !default;
//$input-btn-padding-y-lg:      0.65rem !default;
//$input-btn-padding-x-sm:      1rem !default;
//$input-btn-padding-y-lg:      0.65rem !default;
//$input-btn-padding-x-lg:      1rem !default;

$primary: $theme-black;
$secondary: $theme-green;

$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 4rem,
        4: 3.5rem,
        5: 2.5rem,
        6: 2rem
) !default;

$grid-breakpoints: (
        xx: 0,
        xs: 320px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 2.5,
        6: $spacer * 3,
        7: $spacer * 3.5,
) !default;

$modal-sm:                          480px !default;
$modal-md:                          660px !default;
$modal-lg:                          1140px !default;
$modal-xl:                          1440px !default;

$card-spacer-y:                     $spacer * 0.75 !default;
$card-spacer-x:                     $spacer * 0.75 !default;

$breadcrumb-divider:                quote("//") !default;
$breadcrumb-item-padding-x:         8px;

@mixin animate($property: color, $duration: .2s, $style: linear) {
  -webkit-transition: $property $duration $style;
  -moz-transition: $property $duration $style;
  -o-transition: $property $duration $style;
  -ms-transition: $property $duration $style;
  transition: $property $duration $style;
}