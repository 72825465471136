@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT-Bold.eot');
  src: local('Futura PT Bold'), local('FuturaPT-Bold'),
  url('../fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaPT-Bold.woff2') format('woff2'),
  url('../fonts/FuturaPT-Bold.woff') format('woff'),
  url('../fonts/FuturaPT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url('/../fonts/FuturaPTCond-Book.eot');
  src: local('Futura PT Cond Book'), local('FuturaPTCond-Book'),
  url('../fonts/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaPTCond-Book.woff2') format('woff2'),
  url('../fonts/FuturaPTCond-Book.woff') format('woff'),
  url('../fonts/FuturaPTCond-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('../fonts/suisseintl.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/suisseintl.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/suisseintl.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/suisseintl.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/suisseintl.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/suisseintl.svg#suisseintl') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: url('../fonts/suisseintl-regular.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/suisseintl-regular.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/suisseintl-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/suisseintl-regular.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/suisseintl-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/suisseintl-regular.svg#suisseintl-regular') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: url('../fonts/suisseintl-book.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/suisseintl-book.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/suisseintl-book.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/suisseintl-book.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/suisseintl-book.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/suisseintl-book.svg#suisseintl-book') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Rounded Elegance';
  src: url('../fonts/Rounded_Elegance.ttf');
  src: local('Rounded Elegance'), local('Rounded Elegance'),
  url('../fonts/Rounded_Elegance.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Rounded_Elegance.woff2') format('woff2'),
  url('../fonts/Rounded_Elegance.woff') format('woff'),
  url('../fonts/Rounded_Elegance.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}